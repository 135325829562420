$(document).ready(function(){

    var body = $('body');
    var accHead = $('.acc-head');
    var accSidebar = $('.acc-sidebar');

    function calculateWaypoints() {
        $('.accordion .section-seperator').each(function (index) {
            var waypoint = new Waypoint({
                element: this,
                handler: function() {
                    accSidebar.find('li').removeClass('active');
                    accSidebar.find('li:nth-of-type(' + (index + 1) + ')').addClass('active');
                },
                offset: '25%'
            })
        });
    }

    calculateWaypoints();

    accSidebar.stick_in_parent({offset_top: 120});

    accHead.each(function () {
        $(this).parent().css('height', $(this).outerHeight());
    });


    accHead.on('click', function () {
        calculateWaypoints();
        var giveHeight = $(this).outerHeight() + $(this).next().outerHeight();
        var heightForClose = $('.acc-wrap.active').find('.acc-head').outerHeight();

        if ($(this).parent().hasClass('active')) {
            $(this).parent().toggleClass('active').css('height', $(this).outerHeight());
        } else {
            if ($('.acc-wrap.active').length > 0) {
                $('.acc-wrap.active').removeClass('active').css('height', heightForClose);
                $(this).parent().toggleClass('active').css('height', giveHeight);
            } else {
                $(this).parent().toggleClass('active').css('height', giveHeight);
            }
        }
    });

    accSidebar.on('click', 'li', function () {
        var clickedItem = $(this);
        var clickedIndex = clickedItem.index() + 1;
        $('html, body').animate({
            scrollTop: $('#sep' + clickedIndex).offset().top - 120
        }, 1000);
        setTimeout(function(){
            accSidebar.find('li').removeClass('active');
            clickedItem.addClass('active');
        }, 1050);
    });
});

$(window).on("resize", function () {
    var accHead = $('.acc-head');
    accHead.each(function () {
        $(this).parent().removeClass('active').css('height', $(this).outerHeight());
    });
});