(function ($, window, document) {

    var filesCount = 0;
    var pastUploads = [];
    var channels = 0;
    var rubrics = 0;

    // Listen for the jQuery ready event on the document
    $(function () {
        var datePicker = $('.datepicker');
        window.timePicker = $('.timepicker');

        let $yourCountryInputs = $('.your-country-inputs');
        if ($yourCountryInputs.length) {
            var $countryInput = $yourCountryInputs.find('input[name="media_message[destinationCountry]"]'),
                defaultCountry = $yourCountryInputs.data('default-country').trim(),
                selectedCountry = $countryInput.val();

            toggleRemoteOnlyCategories(defaultCountry, selectedCountry);
        }

        $('.voucher-trigger').on('click', function () {
            $(this).next().toggleClass('active')
        });

        $('.apply-discount').on('click', function () {
            var path = $(this).data('path');
            var code = $('.voucher-input-wrap').find('input').val();

            var pathfull = path.replace('code', code);

            var currentPrice = $('.pay-block h2').html();

            $.ajax({
                method: 'GET',
                url: pathfull,
                success: function (response) {
                    console.log(response);

                    if (response.expired) {
                        $('.voucher-error').show();
                    } else if (response.consumed) {
                        $('.voucher-error').show();
                    } else {
                        var tmp = currentPrice.replace(',', '').replace('€', '');
                        var minus = (+tmp - +response.amount) / 100;

                        var nicer = minus.toFixed(2).replace('.', ',');

                        $('.pay-block h2').html(nicer + '€');
                        $('.voucher-input-wrap').hide();
                        $('.voucher-success').show();
                        $('.voucher-error').hide();
                    }
                },
                error: function () {
                    $('.voucher-error').show();
                }
            });
        });

        if ($(".upload-label").length > 0) {



            var myDropzone = new Dropzone('div.upload-label', {
                url: $(".upload-label").data('endpoint'),
                addRemoveLinks: true,
                maxFilesize: 10,
                maxFiles: 10,
                init: function () {
                    this.on("maxfilesexceeded", function (file) {
                        console.log('exceeded')

                    });
                    this.on("addedfile", function (file) {
                        $('.errmesstoobig').hide();

                        if (file.size > (1024 * 1024 * 5)) {
                            this.removeFile(file);
                            $('.errmesstoobig').show();
                        } else {
                            if (file.uniq) {
                                var fileEl = file.previewElement;
                                $(fileEl).attr('data-uniq', file.uniq);
                            }
                        }
                        ffUp();
                    });
                    this.on("success", function (file) {
                        var thi = JSON.parse(file.xhr.responseText);
                        var fileEl = file.previewElement;
                        $(fileEl).attr('data-uniq', thi.unique_name);
                    });
                    this.on("removedfile", function (file) {
                        if (file) {
                            var uniq = file.previewElement.getAttribute('data-uniq');
                            removeUpload(uniq);
                        }
                        ffDown();
                    });
                }
            });

            myDropzone.on("maxfilesexceeded", function (file) {
                console.log('exceeded')
                myDropzone.removeFile(file);
            });

            let $dropContent = $('.drop-input-content');
            $dropContent.on('click', function (e) {
                $dropContent.parent().trigger('click');
            })

            getCurrFiles(myDropzone);
        }

        $('.legal-checkbox label').on('click', function () {
            $('.legal-show').toggle();
        });

        if ($('#media_message_isCompany').is(':checked')) {
            $('.legal-show').toggle();
        }

        $('.info-message').on('click', function (e) {
            e.preventDefault();
        });

        $('.modal-call').on('click', function (e) {
            e.preventDefault();
            var which = $(this).data('modal');

            $('.modal-' + which).addClass('active');
            $('body').addClass('fixed');
        });

        $('.modal, .modal-close, .modal-content-wrap').on('click', function (e) {
            if (e.target === this) {
                $('.modal').removeClass('active');
                $('body').removeClass('fixed');
            }
        });

        var availableTags = [];

        if(typeof $('#client_extra_receivers') !== 'undefined')
        {
            if(typeof $('#client_extra_receivers').data('extraReceivers') !== 'undefined')
            {
                var availableTags = $('#client_extra_receivers').data('extraReceivers').split(',');
            }
        }

        $('#add-emails').tagit({
            singleField: true,
            fieldName: 'media_message[extraReceivers]',
            autocomplete: {
                delay: 0,
                minLength: 2,
                source : availableTags
            }
        });

        datePicker.each(function () {
            var val = $(this).val();
            if (val) {
                if (!$(this).hasClass('datepicker-report')) {
                    $(this).datepicker('setDate', $(this).val());
                }
            }
        });

        console.log(window.timePicker);

        // timePicker.each(function () {
        //     var val = $(this).val();
        //     if (val) {
        //         if (!$(this).hasClass('timepicker-report')) {
        //             $(this).timepicker('setTime', $(this).val());
        //         }
        //     }
        // });

        $.datepicker.setDefaults(
            $.extend($.datepicker.regional[''])
        );

        $.datepicker.setDefaults({
            minDate: 0
        });

        // $.timepicker.setDefaults(
        //     $.extend($.timepicker.regional[''])
        // );
        //
        // $.timepicker.setDefaults({
        //     minTime: 0
        // });

        /* Lithuanian (UTF-8) initialisation for the jQuery UI date picker plugin. */
        /* @author Arturas Paleicikas <arturas@avalon.lt> */
        jQuery(function ($) {
            $.datepicker.regional['lt'] = {
                closeText: 'Uždaryti',
                prevText: '',
                nextText: '',
                currentText: 'Šiandien',
                monthNames: ['Sausis', 'Vasaris', 'Kovas', 'Balandis', 'Gegužė', 'Birželis',
                    'Liepa', 'Rugpjūtis', 'Rugsėjis', 'Spalis', 'Lapkritis', 'Gruodis'],
                monthNamesShort: ['Sau', 'Vas', 'Kov', 'Bal', 'Geg', 'Bir',
                    'Lie', 'Rugp', 'Rugs', 'Spa', 'Lap', 'Gru'],
                dayNames: ['sekmadienis', 'pirmadienis', 'antradienis', 'trečiadienis', 'ketvirtadienis', 'penktadienis', 'šeštadienis'],
                dayNamesShort: ['sek', 'pir', 'ant', 'tre', 'ket', 'pen', 'šeš'],
                dayNamesMin: ['Se', 'Pr', 'An', 'Tr', 'Ke', 'Pe', 'Še'],
                weekHeader: 'Wk',
                dateFormat: 'yy/mm/dd',
                firstDay: 1,
                isRTL: false,
                showMonthAfterYear: false,
                yearSuffix: ''
            };
            $.datepicker.setDefaults($.datepicker.regional['lt']);
        });

        datePicker.on('click', function () {
            $(this).addClass('opened');
        }).datepicker({
            onClose: function (dateText, inst) {
                datePicker.removeClass('opened');
            }
        });

        // timePicker.on('click', function () {
        //     $(this).addClass('opened');
        // }).timepicker({
        //     onClose: function (dateText, inst) {
        //         datePicker.removeClass('opened');
        //     }
        // });

        $('#media_message_scheduled').on('click', function () {
            $('.schedule-wrap .time-selection').toggleClass('prevented');
        });

        $('#check_all_regions').on('click', function () {
            if ($(this).is(':checked')) {
                $(this).next().html('<span></span>Atžymėti visus');

                $('.your-region-inputs input:not(#check_all_regions)').prop('checked', true);
            } else {
                $(this).next().html('<span></span>Pažymėti visus');

                $('.your-region-inputs input:not(#check_all_regions)').prop('checked', false);
            }
        });

        $('.your-rubric-inputs label').on('click', function () {
            if ($(this).prev().is(':checked')) {
                rubrics = rubrics - 1;
                $(this).parent().removeClass('selected')
            } else {
                rubrics = rubrics + 1;
                $(this).parent().addClass('selected')
            }

            if (rubrics == 3) {
                $('.your-rubric-inputs .input-wrap:not(.selected)').addClass('disabled');
            } else {
                $('.your-rubric-inputs .input-wrap:not(.selected)').removeClass('disabled');
            }

            setTimeout(function () {
                updateRec();
            }, 1);
        });

        $('.your-rubric-inputs label').prev().each(function () {
            if ($(this).is(':checked')) {
                rubrics = rubrics + 1;
            }
        });

        $('.your-channels-inputs label').on('click', function () {
            if ($(this).prev().is(':checked')) {
                channels = channels - 1;
            } else {
                channels = channels + 1;
            }

            setTimeout(function () {
                updateRec();
            }, 1);
        });

        $('.your-region-inputs label').on('click', function () {
            setTimeout(function () {
                updateRec();
            }, 1);
        });

        if(window.translations) {

            $('#time-selection').parent().on('change', function () {
                var isPrevented = $('#time-selection').hasClass('prevented');
                if (canSendFreeMessage) {
                    $('#pay_and_send').text(window.translations[isPrevented ? 'send' : 'plan']);
                    $('#send').text(window.translations[isPrevented ? 'send' : 'plan']);
                } else {
                    $('#pay_and_send').text(window.translations[isPrevented ? 'pay_and_send' : 'pay_and_plan']);
                    $('#send').text(window.translations[isPrevented ? 'pay_and_send' : 'pay_and_plan']);
                }
            });
        }
        if ($yourCountryInputs.length) {
            let countryPrice = $yourCountryInputs.data('country-prices');

            updateCategoryInputs(defaultCountry, defaultCountry);

            $countryInput.on('change', function () {
                selectedCountry = $(this).val();

                toggleRemoteOnlyCategories(defaultCountry, selectedCountry);

                let price = countryPrice[selectedCountry]['message_price'];
                $('.heading-message-price').text(Number(price).toFixed(2));

                updateCategoryInputs(selectedCountry, defaultCountry)
                    .done(function (val) {
                        setTimeout(function () {
                            updateRec();
                        }, 1);
                    })
                    .fail(function (val) {
                        console.log(val);
                    });
            });
        }

        $('.your-channels-inputs label').prev().each(function () {
            if ($(this).is(':checked')) {
                channels = channels + 1;
            }
        });

        $('.your-channels-inputs .input-wrap input').on('change', function () {
            const regionLabels = ['regionine-ziniasklaida', 'regions', 'regional-media'];
            let channelId = parseInt($(this).val()),
                isChecked = $(this).prop("checked");

            if ('LT' !== defaultCountry) {
                //temporary: show regions only in LT
                return;
            }

            $.get(`/api/media-channels/${channelId}`)
                .done(function (res) {
                    let isRegionChannel = regionLabels.indexOf(res.slug) !== -1,
                        isParent = res.children.length;

                    if (isRegionChannel && isParent) {
                        toggleRegions(isChecked);
                    }
                })
                .fail(function (err) {
                    console.log(err);
                });
        });

        setTimeout(function () {
            updateRec();
        }, 1);

        var errMess = $('.error-message').html();

        $('.pay-wrap button.button, .preview-button').on('click', function (e) {
            $('#media_message_termsAgreed').parent().removeClass('not-valid');
            //$('.your-channels-inputs .input-wrap').removeClass('not-valid');
            //$('.your-rubric-inputs .input-wrap').removeClass('not-valid');
            $('.channels-error').removeClass('active');
            $('.rubrics-error').removeClass('active');
            $('.datepicker').parent().removeClass('not-valid');
            $('.timepicker').parent().removeClass('not-valid');

            var toCheck = $('.section-form .check-validation');

            toCheck.each(function () {
                $(this).parent().removeClass('not-valid');
                $(this).parent().next().removeClass('not-valid');

                if ($(this).attr("id") === "media_message_email") {
                    if (!validateEmail($(this).val())) {
                        $(this).parent().addClass('not-valid');
                    }
                } else if ($(this).attr("id") === "media_message_fullName") {
                    var arr = $(this).val().split(" ");

                    var errMessage = $('.media-message-container').data('message-enter-name');

                    console.log(errMessage);
                    if (arr.length < 2 && $(this).val()) {
                        $(this).parent().addClass('not-valid');
                        $(this).next().html(errMessage);
                    } else if (!$(this).val()) {
                        $(this).parent().addClass('not-valid');
                        $(this).next().html(errMess);
                    } else if (arr[1] === '') {
                        $(this).parent().addClass('not-valid');
                        $(this).next().html(errMessage);
                    }
                } else {
                    if (!$(this).val()) {
                        $(this).parent().addClass('not-valid');
                    }
                }
            });

            if (!$('#media_message_termsAgreed').is(':checked')) {
                $('#media_message_termsAgreed').parent().addClass('not-valid');
            }

            let $grecWrap = $('.grec-wrap');
            if ($grecWrap.length && $grecWrap.data('checked') === 'false') {
                $grecWrap.addClass('not-valid');
            }

            if (channels == 0) {
                //$('.your-channels-inputs .input-wrap').addClass('not-valid');
                //$('.channels-error').addClass('active');
            }

            if (rubrics == 0) {
                //$('.your-rubric-inputs .input-wrap').addClass('not-valid');
                //$('.rubrics-error').addClass('active');
            }

            if ($('#media_message_scheduled').is(':checked')) {
                if (!$('.datepicker').val()) {
                    $('.datepicker').parent().addClass('not-valid');
                }
                if (!$('.timepicker').val()) {
                    $('.timepicker').parent().addClass('not-valid');
                }
                if ($('.timepicker').val().length !== 5) {
                    $('.timepicker').parent().addClass('not-valid');
                }
            }

            if ($('.not-valid').length > 0) {
                e.preventDefault();
                $('html, body').animate({
                    scrollTop: $(".not-valid").offset().top - 120
                }, 500);
            }
        });

        $('.con-right .button').on('click', function (e) {
            var toCheck = $('.con-right .check-validation');

            toCheck.each(function () {
                $(this).parent().removeClass('not-valid');

                if ($(this).attr("type") == "email") {
                    if (!validateEmail($(this).val())) {
                        $(this).parent().addClass('not-valid');
                    }
                } else {
                    if (!$(this).val()) {
                        $(this).parent().addClass('not-valid');
                    }
                }
            });

            if ($('.con-right .not-valid').length > 0) {
                e.preventDefault();
                $('html, body').animate({
                    scrollTop: $(".con-right .not-valid").offset().top - 120
                }, 500);
            } else {
                e.preventDefault();

                var chData = $('.con-right form').serialize();

                $.ajax({
                    method: 'POST',
                    url: window.location.pathname,
                    data: chData,
                    success: function (response) {
                        $('.con-right .form-success').prev().addClass('active');
                        $('.con-right .form-success').addClass('active');
                    }
                });
            }
        });


        if(window.translations) {
            var isPrevented = $('#time-selection').hasClass('prevented');
            console.log(isPrevented);
            if (canSendFreeMessage) {
                $('#pay_and_send').text(window.translations[isPrevented ? 'send' : 'plan']);
                $('#send').text(window.translations[isPrevented ? 'send' : 'plan']);
            } else {
                $('#pay_and_send').text(window.translations[isPrevented ? 'pay_and_send' : 'pay_and_plan']);
                $('#send').text(window.translations[isPrevented ? 'pay_and_send' : 'pay_and_plan']);
            }
        }

    });

    function getCurrFiles(myDropzone) {
        $.ajax({
            method: 'GET',
            url: $(".upload-label").data('get-endpoint'),
            success: function (response) {

                $.each(response.uploads, function (key, value) {
                    pastUploads.push({
                        name: value['original_name'],
                        size: '1',
                        accepted: true,
                        uniq: value['unique_name']
                    })
                });

                for (i = 0; i < pastUploads.length; i++) {
                    myDropzone.emit("addedfile", pastUploads[i]);
                    myDropzone.emit("complete", pastUploads[i]);
                    myDropzone.files.push(pastUploads[i]);
                }
            }
        });
    }

    function removeUpload(file) {
        $.ajax({
            method: 'DELETE',
            url: $(".upload-label").data('delete-endpoint'),
            data: {file_name: file},
            success: function (response) {
                console.log(response)
            }
        });
    }

    function ffUp() {
        filesCount = filesCount + 1;
        $('.file-count span').html(filesCount)
    }

    function ffDown() {
        filesCount = filesCount - 1;
        $('.file-count span').html(filesCount)
    }

    function validateEmail(email) {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    function updateRec() {
        let chData = $('form[name=media_message]').serializeArray(),
            channelsIds = [],
            categoryIds = [];

        $('input[name="media_message[mediaChannels][]"]:checked:enabled').each(function () {
            channelsIds.push($(this).val());
        });
        $('input[name="media_message[mediaCategories][]"]:checked:enabled').each(function () {
            categoryIds.push($(this).val());
        });

        if (channelsIds.length > 0 && categoryIds.length > 0) {
            $.ajax({
                method: 'POST',
                url: '/api/receivers/count',
                data: chData,
                success: function (response) {
                    $('.jcount').html(response.receivers);
                }
            });
        } else {
            $('.jcount').html('0');
        }
    }

    function getCategoryIdNameMap(response) {
        let idNameMap = {};

        $.each(response[0], function (index, val) {
            idNameMap[val.id] = val.name;
        });

        return idNameMap;
    }

    function disabledUnmappedInputs($inputs, response) {
        let idNameMap = getCategoryIdNameMap(response);
        $inputs.each(function () {
            let $this = $(this);
            if (typeof idNameMap[$this.val()] !== 'undefined') {
                $this
                    .prop('disabled', true)
                    .parent().fadeOut(100);
            }
        });

        return $inputs;
    }

    function enableInputs($inputs) {
        $inputs
            .prop('disabled', false)
            .parent().fadeIn(100);

        return $inputs;
    }

    function toggleRegions(isChecked) {
        let $showRegion = $('.show-region');

        if (isChecked) {
            $showRegion.addClass('active');
            return;
        }

        $showRegion.removeClass('active');
        $('.your-region-inputs input').attr('checked', false);
    }

    function toggleRemoteOnlyCategories(defaultCountry, selectedCountry) {
        let $remoteOnly = $('input.remote-only');
        if (defaultCountry === selectedCountry) {
            $remoteOnly
                .prop('disabled', true)
                .parent().addClass('invisible');
            return;
        }

        $remoteOnly
            .prop('disabled', false)
            .parent().removeClass('invisible');
    }

    function updateCategoryInputs(selectedCountry, defaultCountry) {
        let deferred = $.Deferred(),
            $channelInputs = $('.your-channels-inputs input'),
            $categoryInputs = $('.your-rubric-inputs input');

        let $nonRemoteCategoryInputs = $channelInputs.add($categoryInputs).filter(':not(.remote-only)');

        if (selectedCountry === 'LT') {
            enableInputs($nonRemoteCategoryInputs);
        }

        if (selectedCountry !== 'LT') {
            //temporary: Show region only in LT
            toggleRegions(false);
        }

        if (selectedCountry === defaultCountry) {
            $('.your-channels-inputs .input-wrap input').trigger('change');
            deferred.resolve('updated');

            return deferred;
        }

        let categoryUrl = '/api/media-categories/unmapped' + '?remoteCountry=' + selectedCountry,
            channelUrl = '/api/media-channels/unmapped' + '?remoteCountry=' + selectedCountry;

        $.when($.get(channelUrl), $.get(categoryUrl))
            .done(function (res1, res2) {
                disabledUnmappedInputs($channelInputs, res1);
                disabledUnmappedInputs($categoryInputs, res2);
                deferred.resolve('updated');
            })
            .fail(function (err) {
                console.log('An error occurred while parsing categories');
                deferred.reject('failed');
            });

        return deferred;
    }

}(window.jQuery, window, document));

