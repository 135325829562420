import Chartist from 'chartist';
import select2 from 'select2';

(function ($, window) {
    $(function () {
        function setAiFormProgress() {
            var step = $('.ai-form-questions.active').data('step');
            let topic = $('.ai-form-topics input[name^="topic"]:checked')[0].value;

            var length = $('.ai-form-topics input[name^="topic"]:checked').data('length');
            length--;
            var width = step / length * 100;
            $('.form-progress-completed').css('width', "".concat(width, "%"));
        }

        $('.modal-ai-form .ai-topic-next').on('click', function () {
            let step = 0;

            // if($('.ai-form-topics input[name^="topic"]').reportValidity()) {
            let topic = $('.ai-form-topics input[name^="topic"]:checked')[0].value;
            if(topic) {
                $('.ai-form-topics').removeClass('active');
                $(`.ai-topic-${topic}.ai-form-question-${step}`).addClass('active');
                setAiFormProgress();
            }
            // }
        });
        $('.modal-ai-form .ai-next').on('click', function () {
            var step = $('.ai-form-questions.active').data('step');
            let topic = $('.ai-form-topics input[name^="topic"]:checked')[0].value;
            if (document.getElementById(`ai-form-step-${topic}-`.concat(step)).reportValidity()) {
                step++;
                $('.ai-form-questions').removeClass('active');
                $(`.ai-topic-${topic}.ai-form-question-`.concat(step)).addClass('active');
                setAiFormProgress();
            }
        });
        $('.modal-ai-form .ai-back').on('click', function () {
            var step = $('.ai-form-questions.active').data('step');
            let topic = $('.ai-form-topics input[name^="topic"]:checked')[0].value;

            step--;
            $('.ai-form-questions').removeClass('active');
            if(step < 0)
            {
                $('.ai-form-topics').addClass('active');
            }
            else {
                $(`.ai-topic-${topic}.ai-form-question-`.concat(step)).addClass('active');
            }
            setAiFormProgress();
        });
        $('.ai-line .button-hide').on('click', function () {
            $('.ai-line').hide();
        });

        $('.modal-ai-form .ai-submit, .ai-regenerate').on('click', function () {
            let payload = {};
            $(`.ai-form-questions`).each(function (index, item) {
                payload[$(item).find('.ai-form-question-title').text()] = $(item).find('.ai-form-question-answer').val();
            });
            let url = "/ai";
            if($(this).hasClass('regenerate-subject'))
            {
                url = url + "?regenerate=subject";
            }
            if($(this).hasClass('regenerate-content'))
            {
                url = url + "?regenerate=content";
            }
            $('.ai-loading-wrapper').show();
            let topic = $('.ai-form-topics input[name^="topic"]:checked')[0].value;
            payload['topic'] = topic;
            console.log(payload);
            $.ajax({
                type: "POST",
                url: url,
                data: payload,
                success: function (result) {
                    if(result.subject) {
                        $('#media_message_subject').val(result.subject);
                    }
                    if(result.content) {
                        CKEDITOR.instances['media_message_content'].setData(result.content);
                    }
                    $('.ai-row .ai-regenerate').addClass('active');
                    $('.ai-loading-wrapper').hide();
                    $('.modal-ai-form .modal-close').click();

                    $('.modal-ai-complete').addClass('active');
                    $('body').addClass('fixed');
                },
                error: function (xhr, ajaxOptions, thrownError) {
                    $('.ai-loading-wrapper').hide();
                    $('.modal-ai-form .modal-close').click();
                }
            });
        });



        $(".owl-carousel").owlCarousel({
            items: 1,
            nav: true
        });

        $('.reminder-continue').on('click', function () {
            $('.go-to-subs').click();
        });

        $('.order-plan-block .modal-call').on('click', function (e) {
            if ($('#client_company_name').val() && $('#client_company_code').val() && $('#client_company_address').val()) {
                $('body').addClass('hide-reminder');
                window.location.href = $(this).data('path');
            }
        });

        $('.reminder-fill').on('click', function () {
            $('.modal-reminder').click();
            $('html, body').animate({
                scrollTop: $('form[name=client_company]').offset().top - 90
            }, 1000);
        });

        $('.trigger').on('click', function () {
            $(this).toggleClass('active');
            $('.navbar ul').toggleClass('active');
        });

        if ($('.hero-right').length > 0) {
            if (Math.random() >= 0.5) {
                $('.hero-right .main-svg').addClass('woman');
            }
        }

        function getRandomInt(min, max) {
            return Math.floor(Math.random() * (max - min + 1) + min);
        }

        function addAnims() {
            if ($('.hero-right .svg').hasClass('replaced-svg')) {
                $('.hero-right .svg').find('.floating').each(function () {
                    var random = getRandomInt(1, 3);
                    var thisItem = $(this);

                    setTimeout(function () {
                        thisItem.addClass('group' + random);
                    }, 399);
                });
            } else {
                setTimeout(function () {
                    addAnims();
                }, 100);
            }
        }

        addAnims();

        if ($(this).scrollTop() > 0) {
            $('.navbar').removeClass('top');
        } else {
            $('.navbar').addClass('top');
        }

        if ($('.form-success-wrap').length > 0) {
            $('.form-success-wrap').parent().addClass('min-height');
        }

        $('.cts').on('click', function (e) {
            if ($('.hero-left').length > 0) {
                e.preventDefault();

                var arr = $(this).attr('href');
                var hash = arr.split('#');

                $('html, body').animate({
                    scrollTop: $("#" + hash[1]).offset().top - 90
                }, 1000);
            }
        });

        $('.logo').on('click', function (e) {
            if ($('.hero-left').length > 0) {
                e.preventDefault();
                $('html, body').animate({
                    scrollTop: 0
                }, 1000);
            }
        });

        var chartData = [];
        var chartColors = [];

        $('.legend:first-of-type ul li').each(function (index, item) {
            index = index + 1;
            var count = $(item).data('count');
            var color = $(item).data('color');
            chartData.push(count);
            chartColors.push('' + color);
        });

        var data = {
            labels: chartData,
            series: chartData
        };

        var options = {
            labelInterpolationFnc: function (value) {
                return value[0]
            }
        };

        var responsiveOptions = [
            ['screen and (min-width: 100px)', {
                chartPadding: 20,
                labelOffset: 85,
                labelInterpolationFnc: function (value) {
                    return value;
                }
            }],
            ['screen and (min-width: 1024px)', {
                labelOffset: 85,
                chartPadding: 20
            }]
        ];

        let $pieChart = $('.pie-chart');

        if ($pieChart.length > 0) {
            new Chartist.Pie('.pie-chart', data, options, responsiveOptions);
        }

        setTimeout(function () {
            $pieChart.find('.ct-series').each(function (index, item) {
                $(item).find('path').css('fill', chartColors[index]);
            })
        }, 1);

        $('.history-trigger').on('click', function () {
            $('.sidebar').toggleClass('active');
        });


        function closeTags() {
            $('.filter-tags').data('trueheight', $('.filter-tags').outerHeight(true));
            $('.filter-tags').css('height', $('.filter-tags-head').outerHeight(true));
            if ($(window).width() > 991) {
                $('.history-grid').css('max-height', 'calc(100vh - 218px - ' + $('.filter-tags-head').outerHeight(true) + 'px)');
            } else {
                $('.history-grid').css('max-height', 'calc(100vh - 60px - ' + $('.filter-tags-head').outerHeight(true) + 'px)');
            }
        }

        function openTags() {
            $('.filter-tags').css('height', $('.filter-tags').data('trueheight'));
            if ($(window).width() > 991) {
                $('.history-grid').css('max-height', 'calc(100vh - 218px - ' + $('.filter-tags').data('trueheight') + 'px)');
            } else {
                $('.history-grid').css('max-height', 'calc(100vh - 60px - ' + $('.filter-tags').data('trueheight') + 'px)');
            }
        }

        closeTags();

        $('.filter-tags-head').on('click', function () {
            if ($(this).hasClass('active')) {
                closeTags();
                $(this).removeClass('active');
            } else {
                openTags();
                $(this).addClass('active');
            }
        });

        $('.attach-tag select').select2({
            minimumResultsForSearch: Infinity
        });

        var tagsfiltered = [];

        $('.filter-tags-body .tag span').on('click', function () {

            var tagname = $(this).parent().data('tag');

            if ($(this).parent().hasClass('active')) {
                var index = tagsfiltered.indexOf(tagname);
                tagsfiltered.splice(index, 1);
                $(this).parent().removeClass('active');
                filtertags();
            } else {
                $(this).parent().addClass('active');
                tagsfiltered.push(tagname);
                filtertags();
            }

            sessionStorage.setItem('filteredtags', tagsfiltered);
        });

        if (sessionStorage.getItem('filteredtags')) {
            var stor = sessionStorage.getItem('filteredtags').split(',');

            stor.forEach(function (ind) {
                $('.filter-tags-body .tag[data-tag=' + ind + '] span').click();
            });
            filtertags();
        }

        function filtertags() {

            if (tagsfiltered.length) {
                $('.single-history').hide();

                tagsfiltered.forEach(function (ind) {
                    $('.single-history.' + ind).show();
                });
            } else {
                $('.single-history').show();
            }
        }

        var errMess = $('.modal-addnew .input-wrap .error-message').html();

        $('.modal-addnew .button').on('click', function () {
            var chData = $('.modal-addnew form').serializeArray();
            $('.modal-addnew .input-wrap').removeClass('not-valid');
            $('.modal-addnew .input-wrap .error-message').html(errMess);

            if ($('#tag_name').val() !== '') {

                $.ajax({
                    method: 'POST',
                    url: '/tags/create',
                    data: chData,
                    success: function (response) {
                        if (response.success) {
                            window.location.reload();
                        } else {
                            $('.modal-addnew .input-wrap').addClass('not-valid');
                            $('.modal-addnew .input-wrap .error-message').html(response.errors[0].message);
                        }
                    }
                });

            } else {
                $('.modal-addnew .input-wrap').addClass('not-valid');
            }
        });

        $('.tag-delete.modal-call').on('click', function () {
            console.log($(this).data('href'));
            $('.modal-removetag .button').attr('data-href', $(this).data('href'));
        });

        $('.modal-removetag .button').on('click', function () {
            var href = $(this).data('href');

            $.ajax({
                method: 'POST',
                url: href,
                success: function (response) {
                    if (response.success) {
                        window.location.reload();
                    } else {
                        window.location.reload();
                    }
                }
            });
        });

        $('.attach-tag .button').on('click', function () {
            var mid = $('.attach-tag').data('mid');

            if ($('.attach-tag select').val() !== '') {
                $.ajax({
                    method: 'POST',
                    url: '/media-messages/' + mid + '/apply-tag/' + $('.attach-tag select').val(),
                    success: function (response) {
                        if (response.success) {
                            window.location.reload();
                        }
                    }
                });
            }
        });

        $('.message-taglist li').on('click', function () {
            var mid = $('.attach-tag').data('mid');

            $.ajax({
                method: 'POST',
                url: '/media-messages/' + mid + '/remove-tag/' + $(this).data('tag-id'),
                success: function (response) {
                    if (response.success) {
                        window.location.reload();
                    }
                }
            });
        });


        $("[data-fancybox]").fancybox({
            buttons: [
                'close'
            ],
            hash: false,
            loop: true
        });
    });

    let $cancelScheduledBtn = $('.btn-cancel-scheduled');
    if ($cancelScheduledBtn.length) {
        $cancelScheduledBtn.on('click', function (e) {
            e.preventDefault();

            let messageId = $(this).parent().data('message-id');

            $.ajax({
                method: 'POST',
                url: `/api/media-messages/${messageId}/cancel`,
                dataType: 'text',
                headers: {
                    'Authorization': `Bearer ${window.token}`
                }
            }).done(function (res) {
                window.location.reload();
            }).fail(function (res) {
                console.error('Could not cancel message');
            });
        })
    }


    $(window).on('scroll', function () {
        if ($(this).scrollTop() > 0) {
            $('.navbar').removeClass('top');
        } else {
            $('.navbar').addClass('top');
        }
    });

    $(window).on('resize', function () {

    });

}(window.jQuery, window));
