//dependencies
const $ = jQuery = require('jquery');
require('jquery-ui-dist/jquery-ui');
require('./src/tag-it/tag-it');
require('waypoints/lib/jquery.waypoints');
require('@fancyapps/fancybox/dist/jquery.fancybox');
require('owl.carousel/dist/owl.carousel');
require('sticky-kit/dist/sticky-kit');
require('simplebar/dist/simplebar');
require('bootstrap');
require('./src/dropzone');

//app
require('./src/accordion');
require('./src/custom');
require('./src/form');
require('./src/login');
require('./src/svg');
require('./src/cookie');
