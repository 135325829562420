const Cookies = require('js-cookie');

(function ($) {
    const COOKIE_CONSENT = 'cookie-consent';
    let $cookieDisclaimer;

    function createConsentCookie() {
        let consentCookie = Cookies.get(COOKIE_CONSENT);
        if (!consentCookie) {
            Cookies.set(COOKIE_CONSENT, 'allowed', { expires: 365 });
            $cookieDisclaimer.fadeOut('fast');
        }
    }

    $(function () {
        $cookieDisclaimer = $('.footer-cookie-disclaimer');

        let consentCookie = Cookies.get(COOKIE_CONSENT);

        if (!consentCookie) {
            $cookieDisclaimer.fadeIn('fast');
        }

        $('.btn-allow-cookies').on('click', function (e) {
            e.preventDefault();
            createConsentCookie();
        })
    });

}(window.jQuery, window, document));