$(document).ready(function(){
    window.recaptchaCallback = function () {
        $('.grec-wrap')
            .data('checked', 'true')
            .removeClass('not-valid');
    };

    $('.forgot-pasw').on('click', function () {
        $('.login-login').addClass('hidden');
        $('.login-reminder').addClass('active');
    });

    $('.back-to-login').on('click', function () {
        $('.login-login').removeClass('hidden');
        $('.login-reminder').removeClass('active');
    });

    $('.login-call').on('click', function () {
        $('body').addClass('fixed');
        $('.login-popup').addClass('active');
    });

    $('.login-popup, .login-close').on('click', function (e) {
        if (e.target === this) {
            $('body').removeClass('fixed');
            $('.login-popup').removeClass('active');
        }
    });

    $('.login-reg-trig').on('click', function () {
        $(this).addClass('hidden');
        $('.login-reg').addClass('active');
    });

    function validateEmail(email) {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    let $loginRegForm = $('.login-reg form'),
        $loginLoginForm = $('.login-login form'),
        $loginReminderForm = $('.login-reminder form');

    $loginReminderForm.attr('novalidate', 'novalidate');
    $loginRegForm.attr('novalidate', 'novalidate');
    $loginLoginForm.attr('novalidate', 'novalidate');

    $loginLoginForm.submit(function (e) {
        e.preventDefault();
        $('.login-login .input-wrap').removeClass('not-valid');
        $('.error-message-login').remove();

        let toCheck = $('.login-login .check-validation');

        toCheck.each(function() {
            $(this).parent().removeClass('not-valid');
            $(this).parent().next().removeClass('not-valid');

            if ($(this).attr("id") === "username") {
                if (!validateEmail($(this).val())) {
                    $(this).parent().addClass('not-valid');
                }
            } else {
                if (!$(this).val()) {
                    $(this).parent().addClass('not-valid');
                }
            }
        });

        if (!$('.login-login .not-valid').length > 0) {
            let $loginLoginForm = $('.login-login form'),
                formData = $loginLoginForm.serialize();

            $.ajax({
                method: 'POST',
                url: $loginLoginForm.attr('action'),
                data: formData,
                success: function (response) {
                    if (response.success) {
                        window.location.reload()
                    } else {
                        $('.login-login form').append('<div class="error-message-login">' + response.message + '</div>')
                    }
                }
            });
        }
    });

    $loginReminderForm.submit(function (e) {
        e.preventDefault();
        $('.login-reminder .input-wrap').removeClass('not-valid');

        let toCheck = $('.login-reminder .check-validation');

        toCheck.each(function() {
            $(this).parent().removeClass('not-valid');

            if ($(this).attr("id") === "password_request_email") {
                if (!validateEmail($(this).val())) {
                    $(this).parent().addClass('not-valid');
                }
            }
        });

        if (!$('.login-reminder .not-valid').length > 0) {
            let $loginReminderForm = $('.login-reminder form'),
                formData = $loginReminderForm.serialize();

            $.ajax({
                method: 'POST',
                url: $loginReminderForm.attr('action'),
                data: formData,
                success: function (response) {
                    if (response.success === false) {
                        $('.reminder-response-text').html('<span class="error">' + response.message + '</span>').show();
                    }
                    if (response.success === true) {
                        $('.reminder-response-text').html('<span class="success">' + response.message + '</span>').show();
                    }
                }
            });
        }
    });

    $('form[name=password_reset]').submit(function (e) {
        e.preventDefault();

        $('.reset-wrap .input-wrap').removeClass('not-valid');
        $('.reset-wrap .pasw-error').remove();
        $('.reset-wrap .input-wrap').find('.error-message').css('opacity', '1');

        var $forgotPasswordForm = $('.forgot-password-form .container'),
            messageDontMatch = 'Slaptažodžiai nesutampa!',
            messageMinimumCount = 'Minimalus simbolių skaičius - 8';

        if ($forgotPasswordForm.length) {
            messageDontMatch = $forgotPasswordForm.data('message-dont-match');
            messageMinimumCount = $forgotPasswordForm.data('message-minimum-count');
        }

        var toCheck = $('.reset-wrap .check-validation');

        toCheck.each(function() {
            $(this).parent().removeClass('not-valid');

            if (!$(this).val()) {
                $(this).parent().addClass('not-valid');
            }

            if ($('#password_reset_plainPassword_first').val() !== $('#password_reset_plainPassword_second').val()) {
                $(toCheck).parent().addClass('not-valid');
                $(toCheck).parent().find('.error-message').css('opacity', '0');
                $(toCheck).parent().append('<div class="error-message pasw-error">' + messageDontMatch + '</div>');
            }

            if ($('#password_reset_plainPassword_first').val().length < 8) {
                $(toCheck).parent().addClass('not-valid');
                $(toCheck).parent().find('.error-message').css('opacity', '0');
                $(toCheck).parent().append('<div class="error-message pasw-error">' + messageMinimumCount + '</div>');
            }
        });

        if (!$('.reset-wrap .not-valid').length > 0) {
            var formData = $('form[name=password_reset]').serialize();

            $.ajax({
                method: 'POST',
                url: $('form[name=password_reset]').attr('action'),
                data: formData,
                success: function () {
                    $('.reset-success').addClass('active');
                }
            });
        }
    });

    $loginRegForm.submit(function (e) {
        e.preventDefault();

        let $clientRegistrationTermsAgreed = $('#client_registration_termsAgreed'),
            $loginInputWrap = $('.login-reg .input-wrap');

        $loginInputWrap.removeClass('not-valid');
        $loginInputWrap.find('.error-message').css('opacity', '1');

        $clientRegistrationTermsAgreed.parent().removeClass('not-valid');
        $('.login-reg .pasw-error').remove();
        $('.error-message-reg').remove();

        var $loginForm = $('.login-reg'),
            messageDontMatch = 'Slaptažodžiai nesutampa!',
            messageMinimumCount = 'Minimalus simbolių skaičius - 8';

        if ($loginForm.length) {
            messageDontMatch = $loginForm.data('message-dont-match');
            messageMinimumCount = $loginForm.data('message-minimum-count');
        }

        var toCheck = $('.login-reg .check-validation');

        toCheck.each(function() {
            $(this).parent().removeClass('not-valid');
            $(this).parent().next().removeClass('not-valid');

            if ($(this).attr("id") === "client_registration_email") {
                if (!validateEmail($(this).val())) {
                    $(this).parent().addClass('not-valid');
                }
            } else {
                if (!$(this).val()) {
                    $(this).parent().addClass('not-valid');
                }
            }

            let $loginPasswordInput = $('.login-reg input[type=password]'),
                $clientRegistrationPlainPasswordFirst = $('#client_registration_plainPassword_first');

            if ($clientRegistrationPlainPasswordFirst.val() !== $('#client_registration_plainPassword_second').val()) {
                $loginPasswordInput.parent().addClass('not-valid');
                $loginPasswordInput.parent().find('.error-message').css('opacity', '0');
                $loginPasswordInput.parent().append('<div class="error-message pasw-error">' + messageDontMatch + '</div>');
            }

            if ($clientRegistrationPlainPasswordFirst.val().length < 8) {
                $loginPasswordInput.parent().addClass('not-valid');
                $loginPasswordInput.parent().find('.error-message').css('opacity', '0');
                $loginPasswordInput.parent().append('<div class="error-message pasw-error">' + messageMinimumCount + '</div>');
            }
        });

        if (!$clientRegistrationTermsAgreed.is(':checked')) {
            $clientRegistrationTermsAgreed.parent().addClass('not-valid');
        }

        let $grecWrap = $('.grec-wrap'),
            isChecked = $grecWrap.data('checked');
        if ($grecWrap.length && isChecked === 'false' || isChecked === false) {
            $grecWrap.addClass('not-valid');
        }

        if (!$('.login-reg .not-valid').length > 0) {
            let $loginForm = $('.login-reg form'),
                formData = $loginForm.serialize();

            $.ajax({
                method: 'POST',
                url: $loginForm.attr('action'),
                data: formData,
                success: function (response) {
                    if (response.success) {
                        $('.reg-success').addClass('active');
                        fbq('track', 'CompleteRegistration', {
                            value: 25.00,
                                currency: 'USD'
                        });
                        window.location.reload();
                    } else {
                        $('.login-reg form').append('<div class="error-message-reg">' + response.errors[0].message + '</div>')
                    }
                }
            });
        }
    });
});
